import { appInjectable } from '@core/di/utils';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { ROUTES } from '@shared/constants/routes';
import { INavigationVM } from '@shared/types/navigation-vm';

@appInjectable()
export class NavigationViewModel implements INavigationVM {
  get recall(): number {
    return this._recall;
  }

  get recallAccounts(): number {
    return this._recallAccounts;
  }

  get recallMarket(): number {
    return this._recallMarket;
  }

  get recallActivity(): number {
    return this._recallActivity;
  }

  get recallSettings(): number {
    return this._recallSettings;
  }

  private _recall = 0;
  private _recallAccounts = 0;
  private _recallMarket = 0;
  private _recallActivity = 0;
  private _recallSettings = 0;

  constructor() {
    appMakeObservable(this, {
      _recall: appObservable,
      _recallAccounts: appObservable,
      _recallMarket: appObservable,
      _recallActivity: appObservable,
      _recallSettings: appObservable,
    });
  }

  handleRecallRoute(path: string) {
    this._recall++;
    switch (path) {
      case ROUTES.mobilePrivate.dashboard:
        this._recallAccounts++;
        break;
      case ROUTES.mobilePrivate.market:
        this._recallMarket++;
        break;
      case ROUTES.mobilePrivate.activity:
        this._recallActivity++;
        break;
      case ROUTES.mobilePrivate.settings.root:
        this._recallSettings++;
        break;
      default:
        break;
    }
  }
}
