import { appInject, appInjectable } from '@core/di/utils';
import { BaseService } from '@core/services/base';
import { appMakeObservable, appObservable } from '@core/state-management/utils';
import { DI_TOKENS } from '@shared/constants/di';
import { IAuthService } from '@shared/types/auth-service';
import { ICardsService } from '@shared/types/card-service';
import { ICardsCooldownCheckVM } from '@shared/types/cards-cooldown-check-vm';
import { IDataResetHandler } from '@shared/types/data-reset-handler';
import { IStorageService, StorageType } from '@shared/types/storage-service';

const CARDS_COOLDOWN_KEY = 'CCK';

@appInjectable()
export class CardsCooldownCheckViewModel
  extends BaseService
  implements ICardsCooldownCheckVM, IDataResetHandler
{
  private storageService = appInject<IStorageService>(DI_TOKENS.storageService);
  private cardService = appInject<ICardsService>(DI_TOKENS.cardService);
  private authService = appInject<IAuthService>(DI_TOKENS.authService);
  private _remainingDays: { days: number; hours: number; minutes: number } = {
    days: 0,
    hours: 0,
    minutes: 0,
  };

  private _tillDate: Date | null = null;
  private _remainingMilliseconds: number | null = null;

  constructor() {
    super();
    appMakeObservable(this, {
      _tillDate: appObservable,
    });
  }

  startTrackTime() {
    if (this._tillDate != null) {
      this._remainingMilliseconds = Date.parse(this._tillDate.toISOString()) - Date.now();
      this._remainingDays = this.convertMilliseconds(this._remainingMilliseconds || 0);
    }
  }

  stopTrackTime() {}

  get remainingMilliseconds() {
    return this._remainingMilliseconds;
  }

  getRemainingDays() {
    return this._remainingDays;
  }

  get isShownFirstCardWarning() {
    //return !!this.storageService.get(StorageType.localStorage, this.generateKey());
    return this.cardService.cardsAmount > 0;
  }

  private generateKey() {
    return `${CARDS_COOLDOWN_KEY}:${this.authService.userId}`;
  }

  private convertMilliseconds(ms: number): { days: number; hours: number; minutes: number } {
    const millisecondsInMinute = 1000 * 60;
    const millisecondsInHour = millisecondsInMinute * 60;
    const millisecondsInDay = millisecondsInHour * 24;

    const days = Math.floor(ms / millisecondsInDay);
    const hours = Math.floor((ms % millisecondsInDay) / millisecondsInHour);
    const minutes = Math.floor((ms % millisecondsInHour) / millisecondsInMinute);

    return { days, hours, minutes };
  }

  handleShowFirstCardWarning = () => {
    this.storageService.set(StorageType.localStorage, this.generateKey(), true);
  };

  fetchCooldownInfo = async () => {
    const cooldown = await this.cardService.getCooldownDate();
    if (cooldown.asJson.blockedTillDate) {
      this._tillDate = cooldown.asJson.blockedTillDate;
      if (this._tillDate != null) {
        this._remainingMilliseconds = Date.parse(this._tillDate.toISOString()) - Date.now();
        this._remainingDays = this.convertMilliseconds(this._remainingMilliseconds || 0);
      }
    } else {
      this._tillDate = null;
      this._remainingMilliseconds = null;
      this._remainingDays = {
        days: 0,
        hours: 0,
        minutes: 0,
      };
    }
  };

  //Erase data protocol - happens on logout
  onDataReset = () => {
    this._tillDate = null;
  };
}
